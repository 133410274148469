export default {
  Message: {
    Confirm: 'Ok',
    Cancel: 'Close',
    Title: 'Message',
    msg401: 'Insufficient authority!',
    msg400: 'System abnormal!',
    msg404: 'Request interface does not exist!',
    msg500: 'Server error!',
    msg502: 'Network error!',
    msg503: 'The service is not available, the server is temporarily overloaded or maintained.',
    LearnMore: 'Learn More',
    AWARDS: 'AWARDS',
    BANKINFO: 'Payment Methods',
    QuoteNow: 'Get a free quote now',
    TESTIMONIAL: 'TESTIMONIAL',
    VIEWMORE: 'VIEW MORE',
    RealCase: 'Real System • Real Case',
    Intern: 'INTERN STUDENTS MESSAGE WALL'
  },
  Home: {
    ViewMore: 'View More',
    NoContent: 'No Content',
    OurServices: 'OUR SERVICES',
    ExpandDetails: 'Expand Details',
    EetractDetails: 'Eetract Details',
    ImmediatelyTips: 'Whatsapp or call 51002088',
    FCQ: 'Free consultation quotation',
    Episodes: 'Number of Episodes',
    LearnMore: 'Learn More >>',
    Shrink: 'Shrink',
    Expand: 'Expand',
    TvpTips: 'How to Apply for the Technology Voucher Programme (TVP)?Get in touch with Eventizer Government Funding Advisory Services right now.',
    BudTips: 'How to Apply for the BUD Fund?Get in touch with Eventizer Government Funding Advisory Services right now.',
    EventName:'Event Name',
    EventOverview:'Event Overview',
    LearnMoreTips:'Learn More',
    GovernmentFunds:'3 Popular Government Funds'
  },
  DeliveryAddress: {
    Address: 'Address'
  },
  Order: {
    OrderNumber: 'Order Number',
    OrderDateTime: 'Order Date & Time',
    TotalPrice: 'TotalPrice',
    OrderStatus: 'Order Status',
    Paid: 'Paid',
    Unpaid: 'Unpaid',
    PayNow: 'PayNow'
  },
  RegNPay: {
    BackText: 'Back',
    UploadButtonText: 'Upload files(jpg, png, pdf)',
    UploadingText: 'Uploading...',
    ConfirmText: 'OK',
    UploadSuccessfulText: 'Upload Successful',
    UploadFailText: 'Upload failed',
    NoFileText: 'No file selected',
    UploadLengthText: 'You can only upload {0} files at most',
    UploadSizeText: 'You can only upload {0} M at most',
    PleaseSelect: 'Please Select',
    SubRecord: 'Submission Record',
    ReferenceNum: 'Reference Number',
    SubmissionDate: 'Submission Date',
    Print: 'Print',
    PlsPayWay: 'Please select the mode of payment',
    PreviewTitleText: 'Please confirm your information again.',
    FormatErrorText: 'Please input correct email address.',
    RequiredText: 'Required'
  },
  Display: {
    SuccessCase: 'Successful Case',
    GeneralQuery: 'General Query',
    OfficeHours: 'Office Hours',
    Hour0: 'Monday to Friday：',
    Hour1: '9:00 a.m. to 12:00 p.m.,',
    Hour2: 'From 1:30 p.m. to 6 p.m',
    Hour3: 'Closed on Saturdays, Sundays and public holidays',
    AddrInfo: "Rm 306, 3 / f, tin's enterprise centre, 777 lai chi kok road, cheung sha wan (cheung sha wan MTR station exit B)"
  },
  Action: {
    ViewMore: 'View More',
    SignUp: 'Sign Up',
    ReturnHomePage: 'Return Home Page',
    OrderDetails: 'Order Details',
    Facebook: 'Facebook',
    IG: 'IG',
    Youtube: 'Youtube',
    Wechat: 'Wechat',
    TikTok: 'TikTok',
    bilibili: 'bilibili',
    RED: 'RED',
    Baidu: 'Baidu',
    Snapchat: 'Snapchat',
    Tmall: 'Tmall',
    Eventizer: 'Eventizer',
    BacktoTop: 'Back to top',
    WhatsApp: 'WhatsApp',
    RegnPay: 'Reg&Pay',
    SearchContent: 'Search Content',
    Search: 'Search',
    Stop: 'Stop',
    Play: 'Play',
    TvpTips: 'How to Apply for the Technology Voucher Programme (TVP)?Get in touch with Eventizer Government Funding Advisory Services right now.',
    Tvp: 'Technology Voucher Program',
    BudTips: 'How to Apply for the BUD Fund? Get in touch with Eventizer Government Funding Advisory Services right now.'
  },
  Cms: {
    PlsChoose: 'Please click to select the content you want to view :',
    SignUp: 'Sign up PayPal  Hong Kong business account via Eventizer',
    Reward: "Here's How to Get Started:",
    First: 'Visit the PayPal Hong Kong Business Account Registration page by clicking on the "Sign up PayPal for free" button.',
    Second: 'Click "Sign Up Now", choose "Business Account", and then follow the instructions to set up your account.',
    Last: 'After registration, once your first customer(s) makes one or more successful transactions totalling at least HKD $600 with PayPal within the first 60 days of your signing up, you will earn HKD $300.',
    Last2: 'Terms and Conditions apply, please refer to ',
    Last3: ' for more details.',
    PayPalHK: 'PayPal HK',
    Register: 'Sign up PayPal for free '
  },
  payment: {
    PaymentSuccessTips: 'Payment Result : Success',
    PaymentFailTips: 'Payment Result : Fail'
  },
  Calendar: {
    KnowMore: 'Know More',
    Time: 'Time',
    Venue: 'Venue',
    NoEvent: 'No activity today'
  },
  photo: {
    photo: 'photo',
    BackText: 'Back to previous'
  },
  product: {
    ProductCode: 'Product Code',
    buy: 'Buy Now',
    addToCart: 'Add To Cart',
    buyNow: 'Buy now',
    favorite: 'Favorite',
    priceTitle: 'Price',
    countTitle: 'Qty',
    youWouldLike: 'You May Also Like',
    moreTips: 'Click to load more',
    none: 'None',
    noneInCat: 'no prodcut belong to this catalog',
    notifyMe: 'Notify Me',
    purchasing: 'purchasing',
    comments: { title: 'Comments' },
    detail: 'Detail',
    overView: 'OverView',
    specification: 'Specification',
    attr: { 0: 'Size', 1: 'Color' },
    SaleOut: 'Sold Out',
    offSale: 'off sale',
    Recommend: 'Recommend',
    Remarks: 'Remarks',
    EnterRemaks: 'please enter remarks',
    up: 'Last',
    down: 'Next',
    logouted: 'you are logouted!',
    loginow: 'login now',
    successInAdding: 'succeed in adding',
    successInRemoving: 'succeed in removing',
    ProductIntro: 'Product introduction',
    ProductIntroduction: 'Product introduction',
    UserReviews: 'User reviews',
    OwnerReply: 'Owner reply',
    per: 'page',
    page: '',
    Screening: 'Screening',
    Resetall: 'Reset all filters',
    PriceHL: 'Price from high to low',
    PriceLH: 'Price from low  to high',
    Producttitle: 'Product List',
    Paixu: 'Sort',
    Total: 'Total',
    Product: 'Products',
    ProductDetail: 'Product Detail',
    Unit: 'Unit'
  },
  CheckOut: {
    Ordersummary: 'Order Summary',
    Input: 'Input',
    expiryDate: 'Expiry Date',
    awsl: 'awsl',
    expressError: 'Express outage!Please choose others.',
    confirmAddress: 'Please confirm the delivery address first',
    pay: 'pay',
    CheckOutTitle: 'Check Out',
    Merchant: 'Merchant',
    Product: 'Product',
    Qty: 'Qty',
    Price: 'Price',
    SplitDelivery: 'Split Delivery',
    ShippingMethod: 'Shipping Method',
    PleaseSelect: 'Please select',
    Selected: 'Selected',
    Name: 'Name',
    Phone: 'Phone',
    Address: 'Address',
    Pickup: 'Pick up',
    Change: 'Change',
    DeliveryCharge: 'Delivery Charge',
    SubTotal: 'Sub-Total',
    PayBy: 'Pay By',
    Couponcode: 'Promotion Code',
    confirm: 'Confirm',
    Total: 'Total',
    BacktoShoppingCart: 'Back to Shopping Cart',
    Last: 'Last',
    Checkout: 'Check Out',
    Next: 'Next',
    Delivery: 'Delivery',
    SelectDeliveryAddress: 'Select delivery address',
    LastName: 'Last Name',
    Edit: 'Edit',
    Delete: 'Delete',
    Select: 'Select',
    NewAddress: 'New Address',
    PickUp: 'Pick Up',
    FirstName: 'First Name',
    ZipCode: 'Zip Code',
    Mobile: 'Mobile',
    Country: 'Area',
    Province: 'Region / Province',
    City: 'District',
    Close: 'Close',
    PickupDate: 'Pick up Date',
    PickupTime: 'Pick up Time',
    Morning: 'Morning',
    Afternoon: 'Afternoon',
    PickAddressError: 'Incomplete PickAddress information',
    AddressLock: 'This Address has been Selected',
    CompanyName: 'Company Name',
    CompanyPhone: 'Company Phone',
    CompanyAddress: 'Company Address',
    PickupAddress: 'Pick up Address',
    Save: 'Save',
    PleaseChoose: 'Please Choose',
    PleaseSelectdate: 'Please Select date',
    PleaseSelecttime: 'Please select time',
    ConfirmationOfOrder: 'Confirmation Of Order',
    Concessions: 'Concessions',
    PaymentMethod: 'Payment Method',
    Complete: 'Complete',
    OrderPreview: 'Order Preview',
    CandP: 'Cant use coupon and promotionCode at the same time.',
    promotionCodeError: 'Please enter the correct promotion code',
    Use: 'Use',
    As: 'As',
    PaymentMethodError: 'The payment method has not been selected yet!',
    OrderSent: 'Order has been sent',
    PaymentProcess: 'Please complete the payment process',

    CountryName: 'Country Name',
    ProvinceName: 'Province Name',
    CityName: 'City Name',
    SFPointType: 'Self Point Type'
  },
  paypalText: {
    TipsA: 'PayPal is now linked to the online system developed by Eventizer',
    TipsB: 'Globally trusted',
    TipsC: 'Customers are protected',
    TipsD: 'Customers stay happy',
    TipsE: 'A variety of payment methods are available to support your B2C business',
    TipsF: 'Your prospective consumers are the 400 million PayPal active users globally'
  }
};
